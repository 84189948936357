import { render } from "./App.vue?vue&type=template&id=0cfa81e6&scoped=true"
import script from "./App.vue?vue&type=script&lang=ts"
export * from "./App.vue?vue&type=script&lang=ts"

import "./App.vue?vue&type=style&index=0&id=0cfa81e6&lang=scss"
import "./App.vue?vue&type=style&index=1&id=0cfa81e6&lang=scss"
import "./App.vue?vue&type=style&index=2&id=0cfa81e6&scoped=true&lang=scss"
script.render = render
script.__scopeId = "data-v-0cfa81e6"

export default script