<template>
  <div class="dialogue">
    <div class="hero">
      <div
        class="picture"
        :class="{ finished: finished && !audioPlaying }"
        :style="`background-image: ${backgroundImage}`"
      ></div>
      <img
        class="button questions-button"
        src="@/assets/messages/zacit_vyslech.png?width=800"
        @click="showQuestions = true"
        v-show="info.questions && finished && !audioPlaying && !showQuestions"
      />
      <img
        class="button back-button"
        src="@/assets/buttons/back.png?width=333"
        @click="goToParent()"
        v-show="showBack"
      />
    </div>
    <player class="player" />
    <div
      :class="[
        'questions-modal',
        {
          'questions-long':
            (info.questions?.length || 0) + (info.questions_locking ? 1 : 0) >=
            4,
        },
      ]"
      v-if="showQuestions && info.questions"
    >
      <div class="questions">
        <div
          class="question prompt"
          v-if="info.question_prompt || info.questions_locking === 'single'"
        >
          <p>{{ info.question_prompt }}</p>
          <p class="single-warning">Máte na výběr pouze jednu z možností!</p>
        </div>
        <button
          v-for="(question, idx) in info.questions"
          :key="question.audio"
          :class="['question', { visited: isVisited(question.id) }]"
          :disabled="!isQuestionAvailable(idx)"
          @click="go(question.id)"
        >
          {{ question.text }}
        </button>
      </div>
      <button class="back-button" @click="showQuestions = false">
        <img src="@/assets/buttons/close.png" />
      </button>
    </div>
    <lock-out
      v-if="info.passwords && !passwordCorrect"
      :password="
        (p) =>
          info.passwords.map((p) => p.toLowerCase()).includes(p.toLowerCase())
      "
      :prompt="info.password_prompt"
      @ok="passwordOk"
    >
      <!-- TODO -->
      <button class="back-button" @click="$router.back()">
        <img src="@/assets/buttons/back.png" />
      </button>
    </lock-out>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Player from "@/components/Player.vue";
import { IDialogue } from "@/types";
import dialogues from "@/data/dialogues";
import TextFrame from "@/components/TextFrame.vue";
import LockOut from "@/components/LockOut.vue";
import { bus } from "@/global";

export default defineComponent({
  components: {
    Player,
    TextFrame,
    LockOut,
  },
  name: "Dialogue",
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data: () => {
    return {
      showQuestions: false,
    };
  },
  computed: {
    info(): IDialogue {
      return dialogues[this.id];
    },
    audioPlaying(): boolean {
      return ["playing", "loading"].includes(this.$store.state.audioState);
    },
    finished(): boolean {
      return this.$store.state.finishedAudios.includes(this.id);
    },
    passwordCorrect(): boolean {
      return this.$store.state.correctPasswordAudios.includes(this.id);
    },
    showBack(): boolean {
      if (!this.info.parent) {
        return false;
      }
      return (
        ["paused", "ended"].includes(this.$store.state.audioState) ||
        (this.finished && this.$store.state.audioState == "unstarted")
      );
    },
    backgroundImage(): string {
      const base_image =
        ((this.info as any) as IDialogue).image ||
        "content/illustrations/DIVOKA_STVANICE";

      return ["webp", "png", "jpg"]
        .map((ext) => {
          return `url(${base_image}.${ext})`;
        })
        .join(", ");
    },
    visitedAll(): boolean {
      if (!this.info.questions) {
        return false;
      }
      return this.info.questions
        .filter((q) => !q.next)
        .map((q) => q.id)
        .every(
          (id) =>
            this.$store.state.visitedAudios.includes(id) ||
            this.$store.state.finishedAudios.includes(id)
        );
    },
  },
  methods: {
    handleFinished() {
      console.log("finished");
      this.$store.commit("addFinishedAudio", this.id);
      (this.info.items || []).forEach((item) => {
        this.$store.commit("addUnlockedItem", item);
      });
      this.showQuestions = true;
      if (this.info.end) {
        this.$router.push({ name: "Dialogue End" });
      }
    },
    passwordOk() {
      this.$store.commit("addCorrectPasswordAudio", this.id);
      bus.emit("play");
    },
    isVisited(audio: string): boolean {
      return this.$store.state.visitedAudios.includes(audio);
    },
    isQuestionAvailable(idx: number) {
      if (!this.info.questions) {
        return true;
      }

      const question = this.info.questions[idx];

      if (question.next) {
        return this.visitedAll;
      }

      switch (this.info.questions_locking) {
        case "sequential":
          const firstUnfinished = this.info.questions?.find(
            (q) => !this.$store.state.finishedAudios.includes(q.id)
          );
          console.log(firstUnfinished);
          return this.info.questions[idx] == firstUnfinished;
        case "single":
          const visitedQuestions = this.info.questions
            .map((q) => q.id)
            .filter((id) => this.$store.state.visitedAudios.includes(id));
          return (
            visitedQuestions.length === 0 ||
            visitedQuestions.includes(question.id)
          );
      }

      return true;
    },
    go(id: string) {
      this.showQuestions = false;
      // this.$router.push({
      //   name: "Dialogue",
      //   params: { id },
      // });
      this.$router.push(`/${id}`).then(() => {
        if (
          (dialogues[id] && !dialogues[id].passwords) ||
          this.$store.state.correctPasswordAudios.includes(id)
        ) {
          bus.emit("play");
        }
      });
    },
    goToParent() {
      this.$router.push({
        name: "Dialogue",
        params: { id: this.info.parent! },
      });
      this.showQuestions = true;
    },
    onIdChange() {
      if (!Object.keys(dialogues).includes(this.id)) {
        this.$router.push({ name: "404" });
      } else {
        this.$store.commit("addVisitedAudio", this.id);
        this.$store.commit("setAudioSrc", this.info.audio);
        if (this.info.audio === null) {
          this.handleFinished();
        }
      }
    },
  },
  watch: {
    id() {
      this.onIdChange();
    },
  },
  mounted() {
    this.onIdChange();
    bus.off("finished");
    bus.on("finished", () => {
      this.handleFinished();
    });
  },
});
</script>

<style lang="scss">
.dialogue {
  height: calc(80vh - 1rem);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  overflow: hidden;
}

.hero {
  position: relative;
  max-width: calc(100% - 2rem);
  min-height: 0;
  flex-grow: 1;
  margin: 1rem;

  & > * {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .picture {
    width: 100%;
    height: 100%;

    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  .picture.finished {
    filter: grayscale(1);
    transition: filter 0.2s;
  }

  .questions-button {
    cursor: pointer;

    width: 80%;
  }
}

.questions-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(1, 1, 1, 0.8);
  z-index: 999;

  font-size: 26px;

  .questions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    flex-grow: 1;

    height: 77vh;
  }

  .question {
    cursor: pointer;
    background: url("../content/bg/02.webp"), url("../content/bg/02.png"), white;

    width: 66%;
    text-align: center;
    padding: 1em;
    border-radius: 1em;
  }

  .question.prompt {
    cursor: unset;
    font-weight: bold;
    font-size: 32px;

    p {
      margin: 0.2em;
    }

    .single-warning {
      font-size: 26px;
    }
  }

  &.questions-long {
    font-size: 18px;

    .prompt {
      font-size: 26px;

      .single-warning {
        font-size: 18px;
      }
    }
  }

  .question.visited {
    filter: brightness(0.5);
  }
}
</style>
