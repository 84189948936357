
import { defineComponent } from "vue";

export default defineComponent({
  name: "Header",
  props: {
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    unvisitedItems(): number {
      return (
        this.$store.state.unlockedItems.length -
        this.$store.state.visitedItems.length
      );
    },
    unvisitedMarkers(): number {
      return (
        this.$store.getters.visibleMapMarkers -
        this.$store.state.maxSeenMapMarkers
      );
    },
    audioPlaying(): boolean {
      return this.$store.state.audioState == "playing";
    },
  },
});
