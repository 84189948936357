<template>
  <div class="scanner">
    <div class="camera-placeholder" v-show="!videoAllowed">
      <img src="@/assets/l/camera.jpg" />
    </div>
    <video ref="video"></video>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import QrScanner from "qr-scanner";
import QrScannerWorkerPath from "!!file-loader!../../node_modules/qr-scanner/qr-scanner-worker.min.js";
QrScanner.WORKER_PATH = QrScannerWorkerPath;

export default defineComponent({
  name: "QrScanner",
  props: {
    enabled: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["output"],
  data() {
    return {
      videoAllowed: false,
      scanner: undefined as QrScanner | undefined,
    };
  },
  watch: {
    enabled(val: boolean) {
      this.updateScannerState(val);
    },
  },
  methods: {
    updateScannerState(state?: boolean) {
      let enabled = state === undefined ? this.enabled : state;
      if (enabled) {
        console.log("Enabling scanner.");
        navigator.mediaDevices
          .getUserMedia({ video: { facingMode: "environment" } })
          .then((stream) => {
            if (stream.getVideoTracks().length > 0) {
              this.videoAllowed = true;
            }
          });
        this.scanner?.start();
      } else {
        console.log("Disabling scanner.");
        this.scanner?.stop();
      }
    },
  },
  mounted() {
    this.scanner = new QrScanner(
      this.$refs.video as HTMLVideoElement,
      (result) => {
        console.debug(`Detected: ${result}`);
        this.$emit("output", result);
      },
      undefined,
      undefined,
      "environment"
    );
    this.updateScannerState();
  },
  beforeUnmount() {
    this.updateScannerState(false);
  },
});
</script>

<style scoped lang="scss">
.scanner {
  position: relative;
  flex-grow: 1;
  align-self: stretch;
}

video,
.camera-placeholder {
  position: absolute;
  top: 1rem;
  left: 1rem;

  width: calc(100% - 2rem);
  height: calc(100% - 2rem);
}

.camera-placeholder {
  overflow: hidden;
}

.camera-placeholder img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  filter: grayscale(0.75) blur(4px) contrast(85%) brightness(33%);
}

video {
  object-fit: cover;
  object-position: center;
}
</style>
