<template>
  <button class="back-button" :class="{ top }" @click="back()">
    <img src="@/assets/buttons/back.png" />
  </button>
</template>


<script lang="ts">
import dialogues from "@/data/dialogues";
import { defineComponent } from "vue";

export default defineComponent({
  name: "BackButton",
  emits: ["clicked"],
  props: {
    top: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    back() {
      this.$emit("clicked");
      if (this.$route.path !== "/") {
        const lastDialogue = this.$store.getters.lastDialogue;
        if (
          dialogues[lastDialogue].end &&
          this.$store.state.finishedAudios.includes(lastDialogue)
        ) {
          this.$router.push({
            name: "Dialogue End"
          });
        } else {
          this.$router.push({
            name: "Dialogue",
            params: {
              id: this.$store.getters.lastDialogue,
            },
          });
        }
      }
    },
  },
});
</script>

<style lang="scss" scoped>
</style>