<template>
  <div id="audiowalk" :class="{ 'non-game': $route.meta.nonGame }">
    <Header
      class="header"
      :disabled="!firstAudioFinished"
      v-show="!$route.meta.nonGame && !$route.meta.hideHeader"
      @camera="showCamera = true"
    />
    <main>
      <router-view />
    </main>

    <audio :src="audioSrc" ref="audio" />

    <transition name="fade">
      <template v-if="!$route.meta.nonGame && $route.meta.subRoute">
        <back-button @clicked="showCamera = false" />
      </template>
    </transition>

    <transition name="fade">
      <CameraView
        v-show="showCamera"
        :enabled="showCamera"
        @close="showCamera = false"
      />
    </transition>

    <div class="fullscreen-modal" v-if="!fullscreen && !$route.meta.nonGame">
      <img
        class="button"
        src="@/assets/messages/hrat_hru.png?width=600"
        @click="enterFullscreen"
      />
    </div>
    <cock-out v-if="$route.path !== '/'" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Header from "./components/Header.vue";
import CameraView from "./components/Camera.vue";
import CockOut from "./components/CockOut.vue";
import TextFrame from "./components/TextFrame.vue";
import { bus } from "./global";
import BackButton from "./components/BackButton.vue";

export default defineComponent({
  name: "Audiowalk",
  components: {
    Header,
    CameraView,
    CockOut,
    TextFrame,
    BackButton,
  },
  data: () => {
    return {
      fullscreen: false,
      showHeader: true,
      showCamera: false,
    };
  },
  computed: {
    audioSrc(): string | null {
      return this.$store.state.audioSrc;
    },
    firstAudioFinished(): boolean {
      return this.$store.state.finishedAudios.length > 0;
    },
  },
  methods: {
    enterFullscreen() {
      document.documentElement.requestFullscreen({ navigationUI: "hide" });
    },
    onFullscreenChange() {
      if (this.$route.meta.nonGame || location.href.includes("internal")) {
        return;
      }
      this.fullscreen =
        this.$store.state.noForceFullscreen ||
        !document.fullscreenEnabled ||
        Boolean(document.fullscreenElement);
      if (this.fullscreen) {
        document.body.classList.remove("not-fullscreen");
      } else {
        document.body.classList.add("not-fullscreen");
      }
    },
  },
  mounted() {
    document.addEventListener("fullscreenchange", (event) => {
      this.onFullscreenChange();
    });
    this.onFullscreenChange();

    const audio: HTMLAudioElement | null = this.$refs.audio as HTMLAudioElement;
    audio.addEventListener("playing", () => {
      console.debug("audio playing");
      this.$store.commit("setAudioState", "playing");
    });
    audio.addEventListener("pause", () => {
      console.debug("audio paused");
      this.$store.commit("setAudioState", "paused");
    });
    audio.addEventListener("stalled", () => {
      console.debug("audio loading (stalled)");
      this.$store.commit("setAudioState", "loading");
    });
    audio.addEventListener("waiting", () => {
      console.debug("audio loading (waiting)");
      this.$store.commit("setAudioState", "loading");
    });
    audio.addEventListener("ended", () => {
      console.debug("audio ended");
      bus.emit("finished");
      this.$store.commit("setAudioState", "ended");
    });

    bus.on("navigate", () => {
      console.log("recv navigate");
      audio.pause();
      console.debug("audio unstarted");
      this.$store.commit("setAudioState", "unstarted");
    });
    bus.on("play", () => {
      console.log("recv play");
      if (this.$store.state.audioSrc) {
        audio.play();
      }
    });
    bus.on("togglePlaying", () => {
      console.log("recv togglePlaying");
      if (audio.paused && this.$store.state.audioSrc) {
        audio.play();
      } else {
        audio.pause();
      }
    });
    bus.on("audioSkip", (amount: unknown) => {
      if (typeof amount == 'number') {
        audio.currentTime += amount;
      }
    });
  },
});
</script>

<style lang="scss">
@use "node_modules/normalize.css/normalize.css";
@import url("https://fonts.googleapis.com/css2?family=Charmonman:wght@400;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Special+Elite&display=swap');

:root {
  font-family: "Charmonman", cursive, sans-serif;

  --var-primary-1: #c78c67;
  --var-primary-2: #613b24;
}
</style>

<style lang="scss">
html,
body {
  background: black;
}

body.not-fullscreen {
  overflow: hidden;
}

#audiowalk {
  position: relative;
  background: url("../content/bg/papir.jpg"), white;
  background-size: cover;

  min-height: 100vh;
  max-width: 66vh;
  margin: auto;

  display: flex;
  flex-direction: column;
}

#audiowalk.non-game {
  max-width: initial;
}

button,
.button {
  cursor: pointer;
  border: none;
  background: transparent;
}

.back-button {
  z-index: 99;

  position: fixed;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);

  height: 12vh;

  border: none;
  background: none;

  &.top {
    top: 10%;
    bottom: unset;
  }

  img {
    max-height: 100%;
  }
}

.fullscreen-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
  background: rgba(1, 1, 1, 0.75);

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  img {
    max-width: 90%;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

a {
  color: black;
  text-decoration: none;
}
</style>

<style scoped lang="scss">
.header {
  height: 20vh;
}

main {
  min-height: 80vh;
}
</style>
