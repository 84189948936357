<template>
  <div
    class="text-frame"
    :class="`style-${style}`"
    :style="{ height }"
    ref="root"
  >
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>


<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "TextFrame",
  props: {
    style: {
      type: String,
      default: "frame",
    },
  },
  data: () => {
    return {
      height: "auto",
    };
  },
  mounted() {
    const root = this.$refs.root as HTMLDivElement;
    const backgroundSrc = getComputedStyle(root)
      .backgroundImage.replace(/url\((['"])?(.*?)\1\)/gi, "$2")
      .split(",")[0];

    var image = new Image();
    image.src = backgroundSrc;
    image.onload = () => {
      const ratio = image.height / image.width;
      this.height = `${root.clientWidth * ratio}px`;
    };
  },
});
</script>

<style lang="scss" scoped>
.text-frame {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.text-frame.style-frame {
  background-image: url("../assets/frame.png");
}

.content {
  color: black;
  font-weight: bold;
  margin: 0 2em;
}
</style>