<template>
  <div class="lock-out" v-if="!authorized && !$route.meta.nonGame">
    <div class="instructions">
      Namiřte fotoaparát na kód na vstupence<br />
      <a @click.prevent="input" href="#">
        (Nebo klikněte zde a zadejte kód ručně.)
      </a>
    </div>
    <scanner :enabled="!authorized" @output="process" />
    <text-frame class="cta">
      <a
        target="_blank"
        href="https://divadlo-leti.cz/events/audiowalk-divoka-stvanice/"
      >
        Koupit vstupenku
      </a>
      <!-- <a href="#" @click="authorize">Dělat jako že mám vstupenku</a> -->
    </text-frame>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import TextFrame from "./TextFrame.vue";
import Scanner from "./Scanner.vue";

export default defineComponent({
  components: { TextFrame, Scanner },
  name: "CockOut",
  data() {
    return {
      checking: false,
    };
  },
  computed: {
    authorized(): boolean {
      return this.$store.state.authorized;
    },
  },
  methods: {
    process(result: string) {
      if (!this.checking) {
        this.checking = true;
        fetch(`https://audiowalk-leti.cz/check/check.php?code=${result}`)
          .then((response) => {
            if (response.ok) {
              this.$store.commit("authorize");
            } else {
              alert("Neplatný kód kód vstupenky!");
            }
          })
          .finally(() => {
            this.checking = false;
          });
      }
    },
    input() {
      this.process(prompt("Zadejte číselný kód vstupenky.") || "");
    },
    authorize() {
      this.$store.commit("authorize");
    },
  },
});
</script>

<style scoped lang="scss">
.lock-out {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100vh;

  z-index: 999;
  background: rgba(1, 1, 1, 0.75);

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.instructions,
.cta {
  font-size: 14pt;
  margin: 1rem;
}

.instructions {
  background: url("../content/bg/02.webp"), url("../content/bg/02.png"), white;
  padding: 1em;
  border-radius: 1em;
  text-align: center;

  a {
    font-size: smaller;
    font-weight: bold;
    text-decoration: underline;
  }
}

.cta {
  text-decoration: underline;
  font-size: 18pt;
}
</style>
