<template>
  <div class="home">
    <button @click="pass">
      <img src="@/assets/messages/hrat_hru.png?width=600" />
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import dialogues from "../data/dialogues";

export default defineComponent({
  name: "Start",
  methods: {
    pass() {
      this.$router.push({
        name: "Dialogue",
        params: {
          id:
            (this.$route.query.target as string | undefined) ||
            Object.keys(dialogues)[0],
        },
      });
    },
  },
  mounted() {
    if (!this.$route.query.target && this.$store.getters.lastZastaveni) {
      this.$router.push({
        name: "Dialogue",
        params: {
          id: this.$store.getters.lastZastaveni,
        },
      });
    }
  },
});
</script>

<style scoped lang="scss">
.home {
  background: rgba(0, 0, 0, 0.75);
  height: 100vh;
}

button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  img {
    width: 80%;
  }
}
</style>
