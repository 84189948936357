<template>
  <div class="inventory">
    <text-frame
      class="item button"
      v-for="(item, key) in items"
      :key="key"
      @click="go(key)"
    >
      {{ item.title }}
    </text-frame>
  </div>
</template>

<script lang="ts">
import { bus } from "@/global";
import { IItem } from "@/types";
import { defineComponent } from "vue";
import TextFrame from "../components/TextFrame.vue";
import items from "../data/items";

export default defineComponent({
  components: {
    TextFrame,
  },
  name: "Inventory",
  computed: {
    items(): { [key: string]: IItem } {
      const result: { [key: string]: IItem } = {};
      this.$store.state.unlockedItems
        .filter((item) => Boolean(items[item]))
        .forEach((item) => {
          result[item] = items[item];
        });
      return result;
    },
  },
  methods: {
    go(id: string) {
      this.$router.push({ name: "Item", params: { name: id } }).then(() => {
        bus.emit("play");
      });
    },
  },
});
</script>

<style scoped lang="scss">
.inventory {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30vh;

  .item {
    width: 70%;
    font-size: 24px;
    margin: 0.5em;

    text-align: center;

    a {
      text-decoration: none;
      color: black;
    }
  }
}
</style>
