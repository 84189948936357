<template>
  <div class="player" :class="{ working, playing, disabled }">
    <img
      class="left-edge working-button"
      src="@/assets/player/left_edge.png"
      @click="skip(-15)"
    />
    <img
      src="@/assets/player/left_mid.png"
      class="left-mid working-button"
      @click="skip(-10)"
    />
    <img
      src="@/assets/player/left_inner.png"
      class="left-inner working-button"
      @click="skip(-5)"
    />
    <img
      :src="
        working
          ? require('@/assets/player/middle_pause.png')
          : require('@/assets/player/middle_play.png')
      "
      class="play-button"
      @click="onClick"
    />
    <img
      src="@/assets/player/right_inner.png"
      class="right-inner working-button"
      @click="skip(5)"
    />
    <img
      src="@/assets/player/right_mid.png"
      class="right-mid working-button"
      @click="skip(10)"
    />
    <img
      src="@/assets/player/right_edge.png"
      class="right-edge working-button"
      @click="skip(15)"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { bus } from "@/global";

export default defineComponent({
  name: "Player",
  computed: {
    working() {
      return (
        this.$store.state.audioState === "playing" ||
        this.$store.state.audioState === "loading"
      );
    },
    playing() {
      return this.$store.state.audioState === "playing";
    },
    disabled() {
      return !this.$store.state.audioSrc;
    },
  },
  methods: {
    skip(amount: number) {
      bus.emit("audioSkip", amount);
    },
    onClick() {
      bus.emit("togglePlaying");
    },
  },
});
</script>

<style scoped lang="scss">
.player {
  display: flex;
  align-items: center;

  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  width: 100%;
  padding: 12px 0;

  .working-button {
    animation: rotation 2s infinite linear;
    animation-play-state: paused;
  }

  .play-button {
    animation: rotation 4s infinite linear;
    animation-play-state: paused;
  }

  &.playing .play-button,
  &.working .working-button {
    animation-play-state: running;
  }

  &.disabled {
    filter: grayscale(1);
    pointer-events: none;
  }
}

img {
  width: 100%;
}

.play-button,
.working-button {
  width: calc(100% / 7 - 5% / 7);

  transform-box: fill-box; // fix iOS bug
}

.play-button {
  width: calc(100% / 7 + 5%);
}

.working-button {
  position: relative;
}

.left-edge {
  right: -35px;
  bottom: -10%;
  transform-origin: 56% 48%;
  animation-direction: reverse !important;
}

.left-mid {
  right: -33px;
  top: -15%;
  transform-origin: 51.5% 52%;
}

.left-inner {
  right: -15px;
  bottom: -25%;
  transform-origin: 46% 51%;
}

.play-button {
  transform-origin: 50.4% 50%;
}

.right-inner {
  left: -15px;
  top: -25%;
  transform-origin: 50% 50%;
}

.right-mid {
  left: -30px;
  bottom: -20%;
  transform-origin: 41% 53%;
  animation-direction: reverse !important;
}

.right-edge {
  left: -45px;
  top: -10%;
  transform-origin: 51% 52%;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-359deg);
  }
}
</style>
