
import { defineComponent } from "vue";
import panzoom from "panzoom";

export default defineComponent({
  name: "Map",
  mounted() {
    this.$store.commit("setMaxSeenMapMarkers", this.markerCount);
    panzoom(this.$refs.root as HTMLElement, {
      maxZoom: 10,
      minZoom: 0.2,
      bounds: true,
    });
  },
  computed: {
    markerCount() {
      return this.$store.getters.visibleMapMarkers;
    },
    mapPhase() {
      return this.$store.state.visitedAudios.includes("kancelar")
        ? "mapa_bez_mlhy"
        : "mapa_mlha";
    },
  },
});
