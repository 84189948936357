import dialogues from "@/data/dialogues";
import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import semverMajor from "semver/functions/major";

function getDefaultState() {
  return {
    audioState: "unstarted",
    audioSrc: null,
    authorized: false,
    finishedAudios: [] as string[],
    visitedAudios: [] as string[],
    maxSeenMapMarkers: 0,
    correctPasswordAudios: [] as string[],
    unlockedItems: [] as string[],
    visitedItems: [] as string[],
    noForceFullscreen: false,
  };
}

export default createStore({
  state: getDefaultState(),
  mutations: {
    authorize(state) {
      state.authorized = true;
    },
    setAudioState(state, audioState) {
      state.audioState = audioState;
    },
    setAudioSrc(state, src) {
      state.audioSrc = src;
    },
    addFinishedAudio(state, audio) {
      if (!state.finishedAudios.includes(audio)) {
        state.finishedAudios.push(audio);
      }
    },
    addVisitedAudio(state, audio) {
      if (!state.visitedAudios.includes(audio)) {
        state.visitedAudios.push(audio);
      }
    },
    setMaxSeenMapMarkers(state, n) {
      if (n > state.maxSeenMapMarkers) {
        state.maxSeenMapMarkers = n;
      }
    },
    addCorrectPasswordAudio(state, audio) {
      if (!state.correctPasswordAudios.includes(audio)) {
        state.correctPasswordAudios.push(audio);
      }
    },
    addUnlockedItem(state, item) {
      if (!state.unlockedItems.includes(item)) {
        state.unlockedItems.push(item);
      }
    },
    addVisitedItem(state, item) {
      if (!state.visitedItems.includes(item)) {
        state.visitedItems.push(item);
      }
    },
    setNoForceFullscreen(state, force) {
      state.noForceFullscreen = force;
    },
    clear(state) {
      Object.assign(state, getDefaultState());
    },
  },
  getters: {
    lastZastaveni(state): string {
      return (
        state.visitedAudios
          .filter((key) => dialogues[key].start)
          .reverse()[0] ||
        Object.entries(dialogues).filter(([_, d]) => d.start)[0][0]
      );
    },
    lastDialogue(state): string | undefined {
      return state.visitedAudios.filter((key) => dialogues[key]).reverse()[0];
    },
    visibleMapMarkers(state): number {
      const allZastaveni = Object.entries(dialogues)
        .filter(([_, d]) => d.start)
        .map(([key, _]) => key);

      return (
        Math.max(
          ...state.visitedAudios.map((audio) => allZastaveni.indexOf(audio)),
          -1
        ) + 2
      );
    },
  },
  actions: {},
  modules: {},
  plugins: [
    createPersistedState({
      key: `audiowalk-${semverMajor(process.env.VUE_APP_VERSION)}`,
      paths: [
        "authorized",
        "finishedAudios",
        "visitedAudios",
        "maxSeenMapMarkers",
        "correctPasswordAudios",
        "unlockedItems",
        "visitedItems",
        "noForceFullscreen",
      ],
    }),
  ],
});
