
import { defineComponent } from "vue";

export default defineComponent({
  name: "End",
  computed: {
    backgroundImage() {
      return ["webp", "png", "jpg"]
        .map((ext) => {
          return `url(content/illustrations/rozlouceni.${ext})`;
        })
        .join(", ");
    },
  },
});
