<template>
  <div class="homepage">
    <img class="logo" src="@/assets/homepage/logo.png?width=600" />
    <div class="text">
      <h1>Audiowalk Divoká Štvanice</h1>
      <div class="subtitle">
        Jsi kořist nebo lovec? Detektivní pátrání po audio stopách zločinu!
      </div>
    </div>
    <div class="home-buttons">
      <a href="https://divadlo-leti.cz/events/audiowalk-divoka-stvanice/">
        <img src="@/assets/homepage/vice_info.png?width=400" />
      </a>
      <a href="https://goout.net/cs/listky/divoka-stvanice/ilgl/">
        <img src="@/assets/homepage/vstupenky_zde.png?width=400"
      /></a>
    </div>
    <div class="home-buttons go-buttons">
      <router-link :to="{ name: 'Start' }">
        <img src="@/assets/homepage/hrat_hru.png?width=600" />
      </router-link>
    </div>
  </div>
</template>

<style scoped>
.homepage {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
  padding: 0 2rem;
  line-height: 1.35;
  min-height: 100vh;
}

h1 {
  color: #462614;
  font-size: 32pt;
  margin: 0 0 0.25em 0;
}

.subtitle {
  color: #2f1b0a;
  font-size: 18pt;
}

.home-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.info-buttons * {
  flex-basis: 50%;
}

.logo {
  align-self: center;
}

img {
  max-width: min(420px, 100%);
}

@media screen and (min-width: 800px) {
  h1 {
    font-size: 48pt;
  }

  .subtitle {
    font-size: 24pt;
  }

  .go-buttons img {
    max-width: unset;
  }
}
</style>
