<template>
  <div class="end">
    <div class="picture" :style="`background-image: ${backgroundImage}`"></div>
    <div class="bonus">(Psst, hledej bonus vedle informační tabule u VILY)</div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "End",
  computed: {
    backgroundImage() {
      return ["webp", "png", "jpg"]
        .map((ext) => {
          return `url(content/illustrations/rozlouceni.${ext})`;
        })
        .join(", ");
    },
  },
});
</script>

<style scoped lang="scss">
.end {
  width: 100%;
  height: calc(80vh - 1rem);

  display: flex;
  flex-direction: column;
}
.picture {
  flex-grow: 1;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.bonus {
  font-size: 18pt;
  text-align: center;
  margin: 1em;
}
</style>
