
import { defineComponent } from "vue";
import Player from "@/components/Player.vue";
import { IItem } from "@/types";
import items from "@/data/items";

export default defineComponent({
  components: {
    Player,
  },
  name: "Item",
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentImageIdx: 0,
    };
  },
  computed: {
    info(): IItem {
      return items[this.name];
    },
    currentImage(): string {
      return this.info.images[this.currentImageIdx];
    },
    // finished(): boolean {
    //   return this.$store.state.finishedAudios.includes(this.id);
    // },
    showBack(): boolean {
      return (
        Boolean(this.info.audio) &&
        ["paused", "ended"].includes(this.$store.state.audioState)
      ); // TODO ???
    },
    backgroundImage(): string {
      const base_image = this.currentImage;

      return ["webp", "png", "jpg"]
        .map((ext) => {
          return `url(${base_image}.${ext})`;
        })
        .join(", ");
    },
  },
  methods: {
    onIdChange() {
      this.$store.commit("addVisitedItem", this.name);
      this.$store.commit("setAudioSrc", this.info.audio);
      // if (this.info.audio === null) {
      //   this.handleFinished();
      // }
    },
    back() {
      this.$router.push(`/${this.$route.query.return || "inventory"}`);
    },
  },
  watch: {
    id() {
      this.onIdChange();
    },
  },
  mounted() {
    if (!Object.keys(items).includes(this.name)) {
      this.$router.push({ name: "404" });
      return;
    }
    this.onIdChange();
  },
});
