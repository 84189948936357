import { IItem } from "@/types";

export default {
  reklama: {
    title: "Reklama",
    images: ["content/items/reklama"],
    audio: "content/audio/Stopa_7.mp3",
  },
  prasatka: {
    title: "Pohádka o prasátkách",
    images: ["content/items/prasatka"],
    audio: "content/audio/Stopa_20.mp3",
  },
  doktoruv_denik: {
    title: "Doktorův deník",
    images: ["content/items/doktor_1", "content/items/doktor_2"],
  },
  dopis_michael: {
    title: "Michaelův dopis doktorovi",
    images: ["content/items/dopis_michael"],
    audio: "content/audio/Stopa_32.mp3",
  },
  agata_noviny: {
    title: "Novinový článek o Agátě",
    images: ["content/items/agata_noviny"],
  },
  albert_clanek: {
    title: "Článek o Albertovi",
    images: ["content/items/albert_clanek"],
    audio: "content/audio/Stopa_31.mp3",
  },
} as { [key: string]: IItem };
