
import { defineComponent } from "vue";

export default defineComponent({
  name: "NotFound",
  mounted() {
    setTimeout(() => {
      this.$router.push("/");
    }, 1000);
  },
});
