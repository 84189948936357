import { IDialogue } from "@/types";

export default {
  uvod: {
    start: true,
    audio: "content/audio/Stopa_1.mp3",
    questions: [
      {
        text: "Agáta von Lasice",
        id: "agata",
      },
      {
        text: "Jožka Krpoch",
        id: "jozka",
      },
      {
        text: "Albert Smutný",
        id: "albert",
      },
      {
        text: "Patricie Labská",
        id: "patricie",
      },
      {
        text: "Richard Prchal",
        id: "richard",
      },
      {
        text: "Jít dál",
        next: true,
        id: "uvod_dal",
      },
    ],
    items: ["reklama"],
  },
  agata: {
    image: "content/illustrations/agata",
    audio: "content/audio/Stopa_2.mp3",
    parent: "uvod",
  },
  jozka: {
    image: "content/illustrations/jozka",
    audio: "content/audio/Stopa_3.mp3",
    parent: "uvod",
  },
  albert: {
    image: "content/illustrations/albert",
    audio: "content/audio/Stopa_4.mp3",
    parent: "uvod",
  },
  patricie: {
    image: "content/illustrations/patricie",
    audio: "content/audio/Stopa_5.mp3",
    parent: "uvod",
  },
  richard: {
    image: "content/illustrations/richard_1",
    audio: "content/audio/Stopa_6.mp3",
    parent: "uvod",
  },
  uvod_dal: {
    audio: "content/audio/Stopa_8.mp3",
    end: "uvod",
  },
  zahradka: {
    image: "content/illustrations/druhe",
    audio: "content/audio/Stopa_9.mp3",
    questions_locking: "single",
    questions: [
      {
        text: "Řeknete mi více informací o zmizelém?",
        id: "zahradka_vice",
      },
      {
        text: "Jak probíhá léčba v tomto sanatoriu?",
        id: "zahradka_lecba",
      },
      {
        text: "Kdy jste Michaela viděla naposledy?",
        id: "zahradka_michael",
      },
    ],
    start: true,
  },
  zahradka_vice: {
    image: "content/illustrations/druhe",
    audio: "content/audio/Stopa_10.mp3",
    questions: [
      {
        text: "Jít dál",
        next: true,
        id: "zahradka_cip",
      },
    ],
  },
  zahradka_lecba: {
    image: "content/illustrations/druhe",
    audio: "content/audio/Stopa_11.mp3",
    questions: [
      {
        text: "Jít dál",
        next: true,
        id: "zahradka_cip",
      },
    ],
  },
  zahradka_michael: {
    image: "content/illustrations/druhe",
    audio: "content/audio/Stopa_12.mp3",
    questions: [
      {
        text: "Jít dál",
        next: true,
        id: "zahradka_cip",
      },
    ],
  },
  zahradka_cip: {
    image: "content/illustrations/druhe",
    audio: "content/audio/Stopa_14_obed.mp3",
    end: "zahradka",
  },
  splav: {
    image: "content/illustrations/patricie",
    audio: "content/audio/Stopa_15.mp3",
    start: true,
    questions: [
      {
        text: "Co mi můžete říct o zmizelém?",
        id: "splav_zmizely",
      },
      {
        text: "Choval se v poslední době podezřele? Co jeho škrábance?",
        id: "splav_skrabance",
      },
      {
        text: "Co tady děláte?",
        id: "splav_co",
      },
      {
        text: "Jak probíhá léčba pana doktora?",
        id: "splav_lecba",
      },
      {
        text: "Rozloučit se",
        next: true,
        id: "splav_konec",
      },
    ],
  },
  splav_zmizely: {
    parent: "splav",
    image: "content/illustrations/patricie",
    audio: "content/audio/Stopa_16.mp3",
  },
  splav_skrabance: {
    parent: "splav",
    image: "content/illustrations/patricie",
    audio: "content/audio/Stopa_17.mp3",
  },
  splav_co: {
    parent: "splav",
    image: "content/illustrations/patricie",
    audio: "content/audio/Stopa_18.mp3",
  },
  splav_lecba: {
    parent: "splav",
    image: "content/illustrations/patricie",
    audio: "content/audio/Stopa_19+20+21.mp3",
    items: ["prasatka"],
  },
  splav_konec: {
    image: "content/illustrations/patricie",
    audio: "content/audio/Stopa_21.mp3",
    end: "splav",
  },
  reka: {
    start: true,
    image: "content/illustrations/richard_1",
    audio: "content/audio/Stopa_22.mp3",
    questions: [
      {
        text: "Zkusit vytáhnout",
        id: "reka_richard",
      },
    ],
  },
  reka_richard: {
    image: "content/illustrations/richard_1",
    audio: "content/audio/Stopa_23.mp3",
    passwords: ["stika", "štika"],
    questions: [
      {
        text: "Prý Michaela znáte nejlépe...",
        id: "reka_richard_michal",
      },
      {
        text: "Doktorova léčba?",
        id: "reka_richard_lecba",
      },
      {
        text: "Patrície mluvila o tom, že obchází myslivec...",
        id: "reka_richard_patricie",
      },
      {
        text: "Jít dál",
        next: true,
        id: "reka_richard_konec",
      },
    ],
  },
  reka_richard_michal: {
    image: "content/illustrations/richard_1",
    audio: "content/audio/Stopa_24.mp3",
    parent: "reka_richard",
  },
  reka_richard_lecba: {
    image: "content/illustrations/richard_1",
    audio: "content/audio/Stopa_25.mp3",
    parent: "reka_richard",
  },
  reka_richard_patricie: {
    image: "content/illustrations/richard_1",
    audio: "content/audio/Stopa_26.mp3",
    parent: "reka_richard",
  },
  reka_richard_konec: {
    image: "content/illustrations/richard_1",
    audio: "content/audio/Stopa_27_obed.mp3",
    end: "reka",
  },
  vila: {
    audio: "content/audio/Stopa_28.mp3",
    image: "content/illustrations/alzbeta",
    questions: [
      {
        text: "Odpovědět",
        id: "vila_prasatka",
      },
    ],
    start: true,
  },
  vila_prasatka: {
    audio: "content/audio/Stopa_29.mp3",
    image: "content/illustrations/alzbeta",
    passwords: ["cihla", "z cihel", "z cihly", "cihly"],
    end: "vila", // TODO
  },
  kancelar: {
    start: true,
    audio: "content/audio/Stopa_30.mp3",
    questions: [
      {
        text: "Prohledat doktorovu kancelář",
        id: "kancelar_doktora",
      },
      {
        text: "Začít výslech",
        id: "kancelar_vyslech",
        next: true,
      },
    ],
  },
  kancelar_vyslech: {
    audio: null,
    questions_locking: "sequential",
    questions: [
      {
        text: "Richard",
        id: "kancelar_richard",
      },
      {
        text: "Patricie",
        id: "kancelar_patricie",
      },
      {
        text: "Agata",
        id: "kancelar_agata",
      },
      {
        text: "Jít dál",
        id: "kancelar_konec",
        next: true,
      },
    ],
  },
  kancelar_richard: {
    audio: null,
    image: "content/illustrations/richard_2",
    questions: [
      {
        text: "Co jste dělal, když se střílelo?",
        id: "kancelar_richard_strelba",
      },
      {
        text: "Co se tu podle vás stalo?",
        id: "kancelar_richard_stalo",
      },
      {
        text: "Jít dál",
        next: true,
        id: "kancelar_richard_dal",
      },
    ],
  },
  kancelar_richard_strelba: {
    audio: "content/audio/Stopa_33.mp3",
    image: "content/illustrations/richard_2",
    parent: "kancelar_richard",
  },
  kancelar_richard_stalo: {
    audio: "content/audio/Stopa_34.mp3",
    image: "content/illustrations/richard_2",
    parent: "kancelar_richard",
  },
  kancelar_richard_dal: {
    audio: null,
    image: "content/illustrations/richard_2",
    questions: [
      {
        text: "Říct o dopisu Michaela",
        id: "kancelar_richard_michal",
      },
      {
        text: "Neříct o dopisu Michaela",
        id: "kancelar_richard_nemichal",
      },
    ],
  },
  kancelar_richard_michal: {
    audio: "content/audio/Stopa_35.mp3",
    image: "content/illustrations/richard_2",
    parent: "kancelar_vyslech",
  },
  kancelar_richard_nemichal: {
    audio: "content/audio/Stopa_36.mp3",
    image: "content/illustrations/richard_2",
    parent: "kancelar_vyslech",
  },
  kancelar_patricie: {
    audio: null,
    image: "content/illustrations/patricie",
    questions: [
      {
        text: "Co jste dělala, když se střílelo?",
        id: "kancelar_patricie_odpoved",
      },
      {
        text: "Proč si myslíte, že je doktor mrtvý?",
        id: "kancelar_patricie_odpoved",
      },
      {
        text: "Myslíte si, že doktora unesl Michael?",
        id: "kancelar_patricie_odpoved",
      },
    ],
  },
  kancelar_patricie_odpoved: {
    audio: "content/audio/Stopa_37.mp3",
    image: "content/illustrations/patricie",
    parent: "kancelar_vyslech",
  },
  kancelar_agata: {
    audio: null,
    image: "content/illustrations/agata",
    questions: [
      {
        text: "Co jste dělala, když se tu střílelo?",
        id: "kancelar_agata_odpoved",
      },
    ],
  },
  kancelar_agata_odpoved: {
    audio: "content/audio/Stopa_38.mp3",
    image: "content/illustrations/agata",
    question_prompt: "Viděla jste...",
    questions: [
      {
        text: "Richarda?",
        id: "kancelar_agata_richard",
      },
      {
        text: "Michaela?",
        id: "kancelar_agata_michael",
      },
      {
        text: "Doktora?",
        id: "kancelar_agata_doktor",
      },
    ],
  },
  kancelar_agata_richard: {
    audio: "content/audio/Stopa_39a+39b.mp3",
    image: "content/illustrations/agata",
    parent: "kancelar_vyslech",
  },
  kancelar_agata_michael: {
    audio: "content/audio/Stopa_40+39b.mp3",
    image: "content/illustrations/agata",
    parent: "kancelar_vyslech",
  },
  kancelar_agata_doktor: {
    audio: "content/audio/Stopa_41+39b.mp3",
    image: "content/illustrations/agata",
    parent: "kancelar_vyslech",
  },
  kancelar_konec: {
    audio: null,
    end: "kancelar",
  },
  most: {
    image: "content/illustrations/most",
    audio: "content/audio/Stopa_42.mp3",
    questions: [
      {
        text: "Rozvázat Jožku",
        id: "most_rozvazat",
      },
      {
        text: "Nerozvázat Jožku",
        id: "most_nerozvazat",
      },
    ],
    start: true,
  },
  most_rozvazat: {
    image: "content/illustrations/most",
    audio: "content/audio/Stopa_43.mp3",
    questions: [
      {
        text: "Jít dál",
        next: true,
        id: "most_delirium",
      },
    ],
    end: "most",
  },
  most_nerozvazat: {
    image: "content/illustrations/most",
    audio: "content/audio/Stopa_44.mp3",
    questions: [
      {
        text: "Jít dál",
        next: true,
        id: "most_delirium",
      },
    ],
    end: "most",
  },
  divocina: {
    image: "content/illustrations/michael",
    audio: "content/audio/Stopa_45.mp3",
    start: true,
    questions: [
      {
        text: "Jít dál",
        next: true,
        id: "divocina_kdo",
      },
    ],
  },
  divocina_kdo: {
    image: "content/illustrations/michael",
    audio: "content/audio/Stopa_46.mp3",
    password_prompt: "Kdo k vám promlouvá?",
    passwords: ["spravce", "správce", "kristián", "kristian"],
    questions: [
      {
        text: "Nezastřelit Michaela",
        id: "divocina_nezastrelit",
      },
      {
        text: "Zastřelit Michaela",
        id: "divocina_zastrelit",
      },
    ],
  },
  divocina_nezastrelit: {
    image: "content/illustrations/michael",
    audio: "content/audio/Stopa_47.mp3",
    questions: [
      {
        text: "Jít dál",
        next: true,
        id: "konec",
      },
    ],
  },
  divocina_zastrelit: {
    image: "content/illustrations/michael",
    audio: "content/audio/Stopa_48.mp3",
    questions: [
      {
        text: "Jít dál",
        next: true,
        id: "konec",
      },
    ],
  },
  bonus: {
    image: "content/illustrations/bonus",
    audio: "content/audio/Stopa_49.mp3",
    start: true,
  },
} as { [key: string]: IDialogue };
