
import BackButton from "@/components/BackButton.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: { BackButton },
  name: "Kancelar",
  mounted() {
    const root = this.$refs.root as HTMLDivElement;
    const img = root.querySelector("img") as HTMLImageElement;
    img.onload = () => {
      root.scrollTo(img.clientWidth / 2 - root.clientWidth / 2, 0);
    };
  },
  methods: {
    unlock(item: string) {
      console.debug(`found ${item}`);

      this.$store.commit("addUnlockedItem", item);
      this.$router.push({
        name: "Item",
        params: { name: item },
        query: { return: "kancelar_doktora" },
      });
      if (item === "dopis_michael") {
        this.$store.commit("addFinishedAudio", "kancelar_doktora");
      }

      if (
        [
          "agata_noviny",
          "prasatka",
          "albert_clanek",
          "dopis_michael",
          "doktoruv_denik",
        ].every((item) => this.$store.state.unlockedItems.includes(item))
      ) {
        this.$store.commit("addVisitedAudio", "kancelar_doktora");
      }
    },
  },
});
