
import { defineComponent } from "vue";
import Header from "./components/Header.vue";
import CameraView from "./components/Camera.vue";
import CockOut from "./components/CockOut.vue";
import TextFrame from "./components/TextFrame.vue";
import { bus } from "./global";
import BackButton from "./components/BackButton.vue";

export default defineComponent({
  name: "Audiowalk",
  components: {
    Header,
    CameraView,
    CockOut,
    TextFrame,
    BackButton,
  },
  data: () => {
    return {
      fullscreen: false,
      showHeader: true,
      showCamera: false,
    };
  },
  computed: {
    audioSrc(): string | null {
      return this.$store.state.audioSrc;
    },
    firstAudioFinished(): boolean {
      return this.$store.state.finishedAudios.length > 0;
    },
  },
  methods: {
    enterFullscreen() {
      document.documentElement.requestFullscreen({ navigationUI: "hide" });
    },
    onFullscreenChange() {
      if (this.$route.meta.nonGame || location.href.includes("internal")) {
        return;
      }
      this.fullscreen =
        this.$store.state.noForceFullscreen ||
        !document.fullscreenEnabled ||
        Boolean(document.fullscreenElement);
      if (this.fullscreen) {
        document.body.classList.remove("not-fullscreen");
      } else {
        document.body.classList.add("not-fullscreen");
      }
    },
  },
  mounted() {
    document.addEventListener("fullscreenchange", (event) => {
      this.onFullscreenChange();
    });
    this.onFullscreenChange();

    const audio: HTMLAudioElement | null = this.$refs.audio as HTMLAudioElement;
    audio.addEventListener("playing", () => {
      console.debug("audio playing");
      this.$store.commit("setAudioState", "playing");
    });
    audio.addEventListener("pause", () => {
      console.debug("audio paused");
      this.$store.commit("setAudioState", "paused");
    });
    audio.addEventListener("stalled", () => {
      console.debug("audio loading (stalled)");
      this.$store.commit("setAudioState", "loading");
    });
    audio.addEventListener("waiting", () => {
      console.debug("audio loading (waiting)");
      this.$store.commit("setAudioState", "loading");
    });
    audio.addEventListener("ended", () => {
      console.debug("audio ended");
      bus.emit("finished");
      this.$store.commit("setAudioState", "ended");
    });

    bus.on("navigate", () => {
      console.log("recv navigate");
      audio.pause();
      console.debug("audio unstarted");
      this.$store.commit("setAudioState", "unstarted");
    });
    bus.on("play", () => {
      console.log("recv play");
      if (this.$store.state.audioSrc) {
        audio.play();
      }
    });
    bus.on("togglePlaying", () => {
      console.log("recv togglePlaying");
      if (audio.paused && this.$store.state.audioSrc) {
        audio.play();
      } else {
        audio.pause();
      }
    });
    bus.on("audioSkip", (amount: unknown) => {
      if (typeof amount == 'number') {
        audio.currentTime += amount;
      }
    });
  },
});
