<template>
  <div class="kancelar" ref="root">
    <div class="plocha">
      <img src="content/bg/kancelar.webp" />
      <div class="item agata" @click="unlock('agata_noviny')"></div>
      <div class="item gramofon" @click="unlock('prasatka')"></div>
      <div class="item albert" @click="unlock('albert_clanek')"></div>
      <div class="item dopis" @click="unlock('dopis_michael')"></div>
      <div class="item denik" @click="unlock('doktoruv_denik')"></div>
    </div>
    <back-button top />
  </div>
</template>

<script lang="ts">
import BackButton from "@/components/BackButton.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: { BackButton },
  name: "Kancelar",
  mounted() {
    const root = this.$refs.root as HTMLDivElement;
    const img = root.querySelector("img") as HTMLImageElement;
    img.onload = () => {
      root.scrollTo(img.clientWidth / 2 - root.clientWidth / 2, 0);
    };
  },
  methods: {
    unlock(item: string) {
      console.debug(`found ${item}`);

      this.$store.commit("addUnlockedItem", item);
      this.$router.push({
        name: "Item",
        params: { name: item },
        query: { return: "kancelar_doktora" },
      });
      if (item === "dopis_michael") {
        this.$store.commit("addFinishedAudio", "kancelar_doktora");
      }

      if (
        [
          "agata_noviny",
          "prasatka",
          "albert_clanek",
          "dopis_michael",
          "doktoruv_denik",
        ].every((item) => this.$store.state.unlockedItems.includes(item))
      ) {
        this.$store.commit("addVisitedAudio", "kancelar_doktora");
      }
    },
  },
});
</script>

<style scoped lang="scss">
.kancelar {
  max-width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  background: black;
}

.plocha {
  position: relative;
  display: inline-block;
  height: 100vh;
  width: auto;

  img {
    height: 100%;
  }
}

.item {
  position: absolute;
  // background: red;
  opacity: 0.5;
}

.gramofon {
  top: 33%;
  left: 38%;
  width: 18%;
  height: 32%;
}

.agata {
  bottom: 4%;
  left: 28%;
  width: 14%;
  height: 20%;
}

.denik {
  bottom: 23%;
  left: 2%;
  width: 17%;
  height: 11%;
}

.dopis {
  top: 72%;
  left: 45.5%;
  width: 19%;
  height: 20%;
}

.albert {
  top: 65%;
  left: 66%;
  width: 15%;
  height: 12%;
}
</style>
