
import { bus } from "@/global";
import { IItem } from "@/types";
import { defineComponent } from "vue";
import TextFrame from "../components/TextFrame.vue";
import items from "../data/items";

export default defineComponent({
  components: {
    TextFrame,
  },
  name: "Inventory",
  computed: {
    items(): { [key: string]: IItem } {
      const result: { [key: string]: IItem } = {};
      this.$store.state.unlockedItems
        .filter((item) => Boolean(items[item]))
        .forEach((item) => {
          result[item] = items[item];
        });
      return result;
    },
  },
  methods: {
    go(id: string) {
      this.$router.push({ name: "Item", params: { name: id } }).then(() => {
        bus.emit("play");
      });
    },
  },
});
