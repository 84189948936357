
import { defineComponent } from "vue";
import Player from "@/components/Player.vue";
import { IDialogue } from "@/types";
import dialogues from "@/data/dialogues";
import TextFrame from "@/components/TextFrame.vue";
import LockOut from "@/components/LockOut.vue";
import { bus } from "@/global";

export default defineComponent({
  components: {
    Player,
    TextFrame,
    LockOut,
  },
  name: "Dialogue",
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data: () => {
    return {
      showQuestions: false,
    };
  },
  computed: {
    info(): IDialogue {
      return dialogues[this.id];
    },
    audioPlaying(): boolean {
      return ["playing", "loading"].includes(this.$store.state.audioState);
    },
    finished(): boolean {
      return this.$store.state.finishedAudios.includes(this.id);
    },
    passwordCorrect(): boolean {
      return this.$store.state.correctPasswordAudios.includes(this.id);
    },
    showBack(): boolean {
      if (!this.info.parent) {
        return false;
      }
      return (
        ["paused", "ended"].includes(this.$store.state.audioState) ||
        (this.finished && this.$store.state.audioState == "unstarted")
      );
    },
    backgroundImage(): string {
      const base_image =
        ((this.info as any) as IDialogue).image ||
        "content/illustrations/DIVOKA_STVANICE";

      return ["webp", "png", "jpg"]
        .map((ext) => {
          return `url(${base_image}.${ext})`;
        })
        .join(", ");
    },
    visitedAll(): boolean {
      if (!this.info.questions) {
        return false;
      }
      return this.info.questions
        .filter((q) => !q.next)
        .map((q) => q.id)
        .every(
          (id) =>
            this.$store.state.visitedAudios.includes(id) ||
            this.$store.state.finishedAudios.includes(id)
        );
    },
  },
  methods: {
    handleFinished() {
      console.log("finished");
      this.$store.commit("addFinishedAudio", this.id);
      (this.info.items || []).forEach((item) => {
        this.$store.commit("addUnlockedItem", item);
      });
      this.showQuestions = true;
      if (this.info.end) {
        this.$router.push({ name: "Dialogue End" });
      }
    },
    passwordOk() {
      this.$store.commit("addCorrectPasswordAudio", this.id);
      bus.emit("play");
    },
    isVisited(audio: string): boolean {
      return this.$store.state.visitedAudios.includes(audio);
    },
    isQuestionAvailable(idx: number) {
      if (!this.info.questions) {
        return true;
      }

      const question = this.info.questions[idx];

      if (question.next) {
        return this.visitedAll;
      }

      switch (this.info.questions_locking) {
        case "sequential":
          const firstUnfinished = this.info.questions?.find(
            (q) => !this.$store.state.finishedAudios.includes(q.id)
          );
          console.log(firstUnfinished);
          return this.info.questions[idx] == firstUnfinished;
        case "single":
          const visitedQuestions = this.info.questions
            .map((q) => q.id)
            .filter((id) => this.$store.state.visitedAudios.includes(id));
          return (
            visitedQuestions.length === 0 ||
            visitedQuestions.includes(question.id)
          );
      }

      return true;
    },
    go(id: string) {
      this.showQuestions = false;
      // this.$router.push({
      //   name: "Dialogue",
      //   params: { id },
      // });
      this.$router.push(`/${id}`).then(() => {
        if (
          (dialogues[id] && !dialogues[id].passwords) ||
          this.$store.state.correctPasswordAudios.includes(id)
        ) {
          bus.emit("play");
        }
      });
    },
    goToParent() {
      this.$router.push({
        name: "Dialogue",
        params: { id: this.info.parent! },
      });
      this.showQuestions = true;
    },
    onIdChange() {
      if (!Object.keys(dialogues).includes(this.id)) {
        this.$router.push({ name: "404" });
      } else {
        this.$store.commit("addVisitedAudio", this.id);
        this.$store.commit("setAudioSrc", this.info.audio);
        if (this.info.audio === null) {
          this.handleFinished();
        }
      }
    },
  },
  watch: {
    id() {
      this.onIdChange();
    },
  },
  mounted() {
    this.onIdChange();
    bus.off("finished");
    bus.on("finished", () => {
      this.handleFinished();
    });
  },
});
