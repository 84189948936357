<template>
  <div class="hledej">
    <img src="@/assets/lupa.png" />
    <h1>Hledej další zastavení!</h1>
  </div>
</template>

<style scoped lang="scss">
.hledej {
  display: flex;
  flex-direction: column;
  height: calc(80vh - 1rem);
  justify-content: center;
  align-items: center;
}
</style>
