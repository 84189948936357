
import { defineComponent } from "vue";
import TextFrame from "./TextFrame.vue";
import Scanner from "./Scanner.vue";

export default defineComponent({
  components: { TextFrame, Scanner },
  name: "CockOut",
  data() {
    return {
      checking: false,
    };
  },
  computed: {
    authorized(): boolean {
      return this.$store.state.authorized;
    },
  },
  methods: {
    process(result: string) {
      if (!this.checking) {
        this.checking = true;
        fetch(`https://audiowalk-leti.cz/check/check.php?code=${result}`)
          .then((response) => {
            if (response.ok) {
              this.$store.commit("authorize");
            } else {
              alert("Neplatný kód kód vstupenky!");
            }
          })
          .finally(() => {
            this.checking = false;
          });
      }
    },
    input() {
      this.process(prompt("Zadejte číselný kód vstupenky.") || "");
    },
    authorize() {
      this.$store.commit("authorize");
    },
  },
});
