
import { defineComponent, PropType } from "vue";
import TextFrame from "./TextFrame.vue";

export default defineComponent({
  components: { TextFrame },
  name: "LockOut",
  data() {
    return {
      input: "",
    };
  },
  emits: ["ok"],
  props: {
    password: {
      type: (() => {}) as PropType<(p: string) => boolean>,
      required: true,
    },
    prompt: {
      type: String,
      required: false,
    },
  },
  watch: {},
  methods: {
    check() {
      if (this.password(this.input)) {
        this.$emit("ok");
      }
    },
  },
  mounted() {},
});
