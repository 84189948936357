
import { defineComponent } from "vue";
import Scanner from "./Scanner.vue";

export default defineComponent({
  components: { Scanner },
  name: "CameraView",
  props: {
    enabled: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    process(result: string) {
      if (result.includes("audiowalk-leti.cz")) {
        const [_, target] = result.split("/#/");
        this.$router.push({ name: "Start", query: { target } });
        this.$emit("close");
      }
    },
  },
});
