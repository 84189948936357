
import { defineComponent } from "vue";
import QrScanner from "qr-scanner";
import QrScannerWorkerPath from "!!file-loader!../../node_modules/qr-scanner/qr-scanner-worker.min.js";
QrScanner.WORKER_PATH = QrScannerWorkerPath;

export default defineComponent({
  name: "QrScanner",
  props: {
    enabled: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["output"],
  data() {
    return {
      videoAllowed: false,
      scanner: undefined as QrScanner | undefined,
    };
  },
  watch: {
    enabled(val: boolean) {
      this.updateScannerState(val);
    },
  },
  methods: {
    updateScannerState(state?: boolean) {
      let enabled = state === undefined ? this.enabled : state;
      if (enabled) {
        console.log("Enabling scanner.");
        navigator.mediaDevices
          .getUserMedia({ video: { facingMode: "environment" } })
          .then((stream) => {
            if (stream.getVideoTracks().length > 0) {
              this.videoAllowed = true;
            }
          });
        this.scanner?.start();
      } else {
        console.log("Disabling scanner.");
        this.scanner?.stop();
      }
    },
  },
  mounted() {
    this.scanner = new QrScanner(
      this.$refs.video as HTMLVideoElement,
      (result) => {
        console.debug(`Detected: ${result}`);
        this.$emit("output", result);
      },
      undefined,
      undefined,
      "environment"
    );
    this.updateScannerState();
  },
  beforeUnmount() {
    this.updateScannerState(false);
  },
});
