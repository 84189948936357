<template>
  <div class="map">
    <div class="map-container" ref="root">
      <img class="map-img" :src="`content/bg/${mapPhase}.webp`" />
      <img
        v-for="n in markerCount"
        :key="n"
        :class="['marker', `zastaveni-${n}`]"
        :src="`/content/bg/marker-${n}.webp`"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import panzoom from "panzoom";

export default defineComponent({
  name: "Map",
  mounted() {
    this.$store.commit("setMaxSeenMapMarkers", this.markerCount);
    panzoom(this.$refs.root as HTMLElement, {
      maxZoom: 10,
      minZoom: 0.2,
      bounds: true,
    });
  },
  computed: {
    markerCount() {
      return this.$store.getters.visibleMapMarkers;
    },
    mapPhase() {
      return this.$store.state.visitedAudios.includes("kancelar")
        ? "mapa_bez_mlhy"
        : "mapa_mlha";
    },
  },
});
</script>

<style scoped lang="scss">
.map {
  background: black;
}

.map-container {
  display: inline-block;
  position: relative;
}

.map-img {
  height: 100vh;
}

.marker {
  position: absolute;
  transform: translate(-50%, -50%);
  width: 1em;
}

.zastaveni-1 {
  left: 28.57%;
  top: 57%;
}

.zastaveni-2 {
  left: 28.65%;
  top: 55%;
}

.zastaveni-3 {
  left: 15.4%;
  top: 60.61%;
}

.zastaveni-4 {
  left: 19.71%;
  top: 53.98%;
}

.zastaveni-5 {
  left: 26.82%;
  top: 53.42%;
}

.zastaveni-6 {
  left: 29.85%;
  top: 52.75%;
}

.zastaveni-7 {
  left: 30.65%;
  top: 47.92%;
}

.zastaveni-8 {
  left: 73.2%;
  top: 41.4%;
}

.zastaveni-9 {
  left: 27.75%;
  top: 54.5%;
  width: 2em;
}
</style>
