<template>
  <header :class="{ disabled: disabled || audioPlaying }">
    <router-link :to="{ name: 'Inventory' }">
      <div class="container">
        <img
          :class="{ unread: Boolean(unvisitedItems) }"
          src="@/assets/buttons/inventory.png"
        />
        <div class="badge" v-if="unvisitedItems">{{ unvisitedItems }}</div>
      </div>
      <label> Zápisník </label>
    </router-link>
    <button class="camera" @click="$emit('camera')">
      <img src="@/assets/buttons/camera.png" />
      <label> Foto </label>
    </button>
    <router-link :to="{ name: 'Map' }">
      <div class="container">
        <img
          :class="{ unread: Boolean(unvisitedMarkers) }"
          src="@/assets/buttons/map.png"
        />
        <div class="badge" v-if="unvisitedMarkers">{{ unvisitedMarkers }}</div>
      </div>
      <label> Mapa </label>
    </router-link>
  </header>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Header",
  props: {
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    unvisitedItems(): number {
      return (
        this.$store.state.unlockedItems.length -
        this.$store.state.visitedItems.length
      );
    },
    unvisitedMarkers(): number {
      return (
        this.$store.getters.visibleMapMarkers -
        this.$store.state.maxSeenMapMarkers
      );
    },
    audioPlaying(): boolean {
      return this.$store.state.audioState == "playing";
    },
  },
});
</script>

<style scoped lang="scss">
header {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

button,
a {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 0.33rem;
  padding: 0.33rem;

  color: black;
  text-decoration: none;
  font-size: 24px;
  font-weight: bold;

  & label {
    margin-top: 0.33rem;
  }

  img {
    max-height: 12vh;
    max-width: 100%;
    transition: filter 0.2s;
  }

  &:active {
    img {
      filter: brightness(0.5);
    }
  }
}

header > * {
  transition: filter 0.2s;
}

header.disabled > * {
  filter: grayscale(1) brightness(80%);
  pointer-events: none;
  cursor: initial;
}

.container {
  position: relative;
}

.badge {
  position: absolute;
  top: -2px;
  right: -2px;

  color: white;
  background: red;

  font-weight: bold;
  text-align: center;

  width: 32px;
  height: 32px;
  // line-height: 32px;

  font-size: 30px;

  border-radius: 50%;
  box-shadow: 0 0 1px #333;
}
</style>
