<template>
  <div class="not-found">???</div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "NotFound",
  mounted() {
    setTimeout(() => {
      this.$router.push("/");
    }, 1000);
  },
});
</script>

<style scoped lang="scss">
.not-found {
  height: 80vh;
  text-align: center;
  line-height: 80vh;
  font-size: 33vh;
}
</style>
