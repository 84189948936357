import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import Start from "../views/Start.vue";
import Map from "../views/Map.vue";
import Inventory from "../views/Inventory.vue";
import Item from "../views/Item.vue";
import Dialogue from "../views/Dialogue.vue";
import DialogueEnd from "../views/DialogueEnd.vue";
import Kancelar from "../views/Kancelar.vue";
import NotFound from "../views/404.vue";
import End from "../views/End.vue";
import Homepage from "../views/Homepage.vue";
import { bus } from "@/global";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Homepage",
    component: Homepage,
    meta: { nonGame: true, hideHeader: true, free: true },
  },
  {
    path: "/start",
    name: "Start",
    component: Start,
    meta: { hideHeader: true },
  },
  {
    path: "/:id",
    name: "Dialogue",
    component: Dialogue,
    props: true,
  },
  {
    path: "/hledej",
    name: "Dialogue End",
    component: DialogueEnd,
  },
  {
    path: "/konec",
    name: "End",
    component: End,
  },
  {
    path: "/map",
    name: "Map",
    component: Map,
    meta: { subRoute: true, hideHeader: true },
  },
  {
    path: "/inventory",
    name: "Inventory",
    component: Inventory,
    meta: { subRoute: true },
  },
  {
    path: "/inventory/:name",
    name: "Item",
    component: Item,
    props: true,
  },
  {
    path: "/internal/",
    alias: "/internal/codes",
    name: "Internal",
    component: () => import("../views/Internal.vue"),
    meta: { nonGame: true },
  },
  {
    path: "/internal/camtest",
    name: "Internal Camera Test",
    component: () => import("../views/CamTest.vue"),
    meta: { nonGame: true },
  },
  {
    path: "/kancelar_doktora",
    name: "Kancelar",
    component: Kancelar,
    meta: { hideHeader: true },
  },
  {
    path: "/404",
    name: "404",
    component: NotFound,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from) => {
  bus.emit("navigate");
});

export default router;
