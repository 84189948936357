
import { defineComponent } from "vue";
import { bus } from "@/global";

export default defineComponent({
  name: "Player",
  computed: {
    working() {
      return (
        this.$store.state.audioState === "playing" ||
        this.$store.state.audioState === "loading"
      );
    },
    playing() {
      return this.$store.state.audioState === "playing";
    },
    disabled() {
      return !this.$store.state.audioSrc;
    },
  },
  methods: {
    skip(amount: number) {
      bus.emit("audioSkip", amount);
    },
    onClick() {
      bus.emit("togglePlaying");
    },
  },
});
