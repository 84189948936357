
import { defineComponent } from "vue";
import dialogues from "../data/dialogues";

export default defineComponent({
  name: "Start",
  methods: {
    pass() {
      this.$router.push({
        name: "Dialogue",
        params: {
          id:
            (this.$route.query.target as string | undefined) ||
            Object.keys(dialogues)[0],
        },
      });
    },
  },
  mounted() {
    if (!this.$route.query.target && this.$store.getters.lastZastaveni) {
      this.$router.push({
        name: "Dialogue",
        params: {
          id: this.$store.getters.lastZastaveni,
        },
      });
    }
  },
});
