<template>
  <div class="camera">
    <scanner :enabled="enabled" @output="process" />
    <button class="back-button" @click="$emit('close')">
      <img src="@/assets/buttons/close.png" />
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Scanner from "./Scanner.vue";

export default defineComponent({
  components: { Scanner },
  name: "CameraView",
  props: {
    enabled: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    process(result: string) {
      if (result.includes("audiowalk-leti.cz")) {
        const [_, target] = result.split("/#/");
        this.$router.push({ name: "Start", query: { target } });
        this.$emit("close");
      }
    },
  },
});
</script>

<style scoped lang="scss">
.camera {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.66);

  display: flex;
}
</style>
