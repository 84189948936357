
import { defineComponent } from "vue";

export default defineComponent({
  name: "TextFrame",
  props: {
    style: {
      type: String,
      default: "frame",
    },
  },
  data: () => {
    return {
      height: "auto",
    };
  },
  mounted() {
    const root = this.$refs.root as HTMLDivElement;
    const backgroundSrc = getComputedStyle(root)
      .backgroundImage.replace(/url\((['"])?(.*?)\1\)/gi, "$2")
      .split(",")[0];

    var image = new Image();
    image.src = backgroundSrc;
    image.onload = () => {
      const ratio = image.height / image.width;
      this.height = `${root.clientWidth * ratio}px`;
    };
  },
});
