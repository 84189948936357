<template>
  <div class="lock-out">
    <div :class="['container', { 'with-prompt': Boolean(prompt) }]">
      <text-frame class="prompt" v-if="prompt">
        {{ prompt }}
      </text-frame>
      <div class="password-entry">
        <img src="@/assets/messages/zadej_heslo.png?width=800" />
        <input class="password" type="text" v-model="input" @input="check" />
      </div>
      <slot> </slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import TextFrame from "./TextFrame.vue";

export default defineComponent({
  components: { TextFrame },
  name: "LockOut",
  data() {
    return {
      input: "",
    };
  },
  emits: ["ok"],
  props: {
    password: {
      type: (() => {}) as PropType<(p: string) => boolean>,
      required: true,
    },
    prompt: {
      type: String,
      required: false,
    },
  },
  watch: {},
  methods: {
    check() {
      if (this.password(this.input)) {
        this.$emit("ok");
      }
    },
  },
  mounted() {},
});
</script>

<style scoped lang="scss">
.lock-out {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100vh;

  z-index: 999;
  background: rgba(1, 1, 1, 0.75);

  &.with-prompt {
    margin-bottom: 4rem;
  }
}

.container {
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.container.with-prompt {
  height: 80vh;
}

.prompt {
  font-size: 15pt;
}

.password-entry {
  width: 80%;

  img {
    width: 100%;
  }

  input {
    position: relative;
    width: 60%;
    bottom: 38%;
    left: 50%;
    transform: translateX(-50%);

    font-family: "Special Elite", "Courier New", Courier, monospace;
    font-size: 15pt;
    text-transform: uppercase;

    border: none;
    background: transparent;

    text-align: center;

    &:focus {
      outline: none;
    }
  }
}
</style>
